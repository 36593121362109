<template>
    <div class="d-flex flex-column flex-root flex-grow" style="height: 100vh">
        <div class="d-flex flex-column flex-lg-row flex-row-fluid bg-white">
            <div class="d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-15"
                 :style="{ backgroundImage: `url(${backgroundImage})` }"
            >
                <div class="d-flex flex-row-fluid flex-column justify-content-between">
                    <div
                        class="flex-column-fluid d-flex flex-column justify-content-center text-center"
                    >
                        <a href="#" class="text-center">
                            <img src="media/logos/Logo_white.png" class="h-50">
                        </a>

                        <div>
                            <h3 class="font-size-h1 text-white">
                                {{ $t('SLOGAN.HEADER') }}
                            </h3>
                            <p class="font-weight-lighter text-white opacity-80">
                                {{ $t('SLOGAN.DESC') }}
                            </p>
                        </div>
                    </div>
                    <!--end: Aside content -->
                    <!--begin: Aside footer for desktop -->
                    <div
                        class="d-none flex-column-auto d-lg-flex justify-content-between mt-15"
                    >
                        <div class="opacity-70 font-weight-bold text-white">
                            © 2020 LUMASERV
                        </div>
                        <div class="d-flex">
                            <a href="mailto:cloud@lumaserv.com" class="text-white">E-Mail</a>
                            <a href="tel:+4926116006719" class="text-white ml-10">24/7 Hotline</a>
                            <a href="https://lumaserv.com/impressum" class="text-white ml-10">{{ $t('AUTH.GENERAL.IMPRINT') }}</a>
                        </div>
                    </div>
                    <!--end: Aside footer for desktop -->
                </div>
                <!--end: Aside Container -->
            </div>
            <!--begin::Aside-->

            <!--begin::Content-->
            <div
                class="flex-row-fluid d-flex flex-column position-relative p-7 overflow-hidden"
            >
                <div class="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
                    <router-view />
                </div>
            </div>
            <!--end::Content-->
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: 'Auth',
    computed: {
        ...mapState({
            errors: state => state.auth.errors
        }),
        backgroundImage () {
            return process.env.BASE_URL + 'media/bg/bg-2.jpg'
        }
    },
    methods: {}
}
</script>
